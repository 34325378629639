import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import "./cryptolist.css"
import { BiChevronDown } from 'react-icons/bi';
import trc from "./images/tronc.png"
import btc from "./images/btc.png"
import eth from "./images/eth.png"
import usdt from "./images/usdt.png"

export default function CryptoList({formik}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = useState("Usdt Trc-20")

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    console.log("event",event)
    console.log("event",event.currentTarget)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChoose = (e) => {
    console.log("elemt", e)
    console.log("elemt", e.currentTarget.id)

    let crp = e.currentTarget.id;
    switch (crp) {
      case "BTC":
        formik.setValues({
          ...formik.values, crypto: e.currentTarget.id, wallet: "", xaf: 0, eu: 0, amount: 0,blockchain: "btc",
          rate: { EUR: formik.values.rateApi[formik.values.crypto].EUR, USD: formik.values.rateApi[formik.values.crypto].USD }
        }, true)
        setState("Bitcoin")
        break;

      case "ETH":
        formik.setValues({
          ...formik.values, crypto: e.currentTarget.id, wallet: "", xaf: 0, eu: 0, amount: 0,blockchain: "eth",
          rate: { EUR: formik.values.rateApi[formik.values.crypto].EUR, USD: formik.values.rateApi[formik.values.crypto].USD }
        }, true)
        setState("Etherium")
        break;
      case "USDT":
        formik.setValues({
          ...formik.values, crypto: e.currentTarget.id, wallet: "", xaf: 0, eu: 0, amount: 0,blockchain: "usdt20",
          rate: { EUR: formik.values.rateApi[formik.values.crypto].EUR, USD: formik.values.rateApi[formik.values.crypto].USD }
        }, true)
        setState("Usdt Erc-20")
        break;
      case "TRC20":
        formik.setValues({
          ...formik.values, crypto: e.currentTarget.id, wallet: "", xaf: 0, eu: 0, amount: 0,blockchain: "trx",
          rate: { EUR: formik.values.rateApi[formik.values.crypto].EUR, USD: formik.values.rateApi[formik.values.crypto].USD }
        }, true)
        setState("Usdt Trc-20")
        break;
    
      default:
        break;
    }
    
    // formik.setValues({
    //   ...formik.values, crypto: e.currentTarget.id, wallet: "", xaf: 0, eu: 0, amount: 0,blockchain: "btc",
    //   rate: { EUR: formik.values.rateApi[formik.values.crypto].EUR, USD: formik.values.rateApi[formik.values.crypto].USD }
    // }, true) 



    handleClose()
  }

  console.log("formik", formik)
  console.log("formik value crypto", formik.values.crypto)

  return (
    <div className='crypto_list'>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <span id='plet'>{state}</span>
         <span className='flec'><BiChevronDown size={32} /></span>
      </Button>
      <Menu
            transformOrigin={{
              vertical: -57,
              horizontal: 0,
          }}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem id="TRC20" className="Usdt Trc-20"  onClick={(e)=>handleChoose(e)}><div className='plex'><span>Usdt Trc 20</span>  <img className='crp_tof' src={trc} alt="photo trc" /></div></MenuItem>
        <MenuItem id="BTC" className="Bitcoin" onClick={(e)=>handleChoose(e)}><div className='plex'><span>Bitcoin</span> <img className='crp_tof' src={btc} alt="photo bitcoin" /></div></MenuItem>
        <MenuItem id="USDT" className="Usdt Erc-20"  onClick={(e)=>handleChoose(e)}><div className='plex'><span>Usdt Erc-20</span>  <img className='crp_tof' src={usdt} alt="photo usdt" /></div></MenuItem>
        <MenuItem id="ETH" className="Etherium"  onClick={(e)=>handleChoose(e)}><div className='plex'><span>Etherium</span>  <img className='crp_tof' src={eth} alt="photo etherium" /></div></MenuItem>
      </Menu>
    </div>
  );
}
