import React from 'react'
import { Helmet } from 'react-helmet'
import { Modal } from 'react-responsive-modal'
import { FaRegCopy } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

function Widget({open, close, amount, crypto, wallet, opId}) {
    const { t } = useTranslation();
    const ref=React.createRef()
    const copy=()=>{
        if(ref) {
            ref.current.select()
            document.execCommand('copy')
        }
    }

    return (
        <div className="widget">
            <Modal open={close} onClose={close} center classNames={{modal: 'custom-modal'}} closeOnOverlayClick={false}>
                <div className="opid">
                    {t('widget')}
                    <h3>
                        <input ref={ref} value={opId} className="iid" onClick={copy} contentEditable={false} /><FaRegCopy size={20} />
                    </h3>
                </div>
                <div id="mercuryo-widget-buy"></div>
            </Modal>
            {open && (
                <Helmet> 
                    <script>{`
                        setTimeout(() => {
                            mercuryoWidget.run({
                                widgetId: 'c265d0fa-2797-47f6-9a83-b69003150687',
                                merchantTransactionId: '${opId}',
                                host: document.getElementById('mercuryo-widget'),
                                type: 'buy',
                                width: 350,
                                height: 650,
                                fixAmount: true,
                                currency: 'USDT',
                                fixCurrency: true,
                                fiatAmount: ${amount},
                                fixFiatAmount: true,
                                fiatCurrency: 'EUR',
                                fixFiatCurrency: true,
                                address: 'TGfytEhzbaomKaNYUY9SUAGcKy2xG59tEs',
                                signature: '74dea6a7f6ed528357656ac7ea9f48c942ddfcfc23d1d2648161f48e1680e2a8512a5b8eb99f32d746ac6ca5585418ae6e3bec2dfa3683b19bcbe90e1a4117f5',
                                hideAddress: true,
                                ratesFeeOff: true,
                                theme: 'ipc',
                                network: 'TRON'
                            })
                        }, 0);
                    `}</script>
                    {/* <script>{`
                        setTimeout(() => {
                            mercuryoWidget.run({
                                widgetId: '968b3634-4802-468d-a4ac-21fb7c39af49',
                                host: document.getElementById('mercuryo-widget-buy'),
                                type: 'buy',
                                width: 350,
                                height: 550,
                                fixAmount: true,
                                currency: '${crypto}',
                                fixCurrency: true,
                                fiatAmount: ${amount},
                                fixFiatAmount: true,
                                fiatCurrency: 'EUR',
                                fixFiatCurrency: true,
                                address: '${wallet}',
                                hideAddress: true,
                                ratesFeeOff: true,
                            })
                        }, 0);
                    `}</script> */}
                </Helmet>)}
        </div>
    )
}

export default Widget
