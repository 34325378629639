import React from 'react'

import './partner.css'
import mercuryo from './images/mercuryo.png';
import mtn from './images/mtn.png';
import orange from './images/orange.png';
import sepa from './images/sepa.png';
import free from './images/free.png';
import mobiBurki from './images/mobi-burki.png';
import mobi from './images/mobi-mali.png'
import moov from './images/moov.png';
import wizall from './images/master.png';
import visa from './images/visa.png';
import cinetpay from './images/cinetpay.png'
import intouch from './images/intouch.png'

function Partner() {
    return (
        <div className="partners">
            <div className="partners-container">
                <img src={mercuryo} alt="mercurio"/>
                <img src={orange} alt="orange"/>
                <img src={mobi} alt="mobimali"/>
                <img src={wizall} alt="wizall"/>
                <img src={cinetpay} alt="cinetpay"/>
                <img src={free} alt="free"/>
                <img src={mtn} style={{marginRight:"60px"}} alt="mtn"/>
                <img src={sepa} alt="sepa"/>
                <img src={moov} style={{marginRight:"75px"}} alt="moov"/>
                <img src={visa} style={{marginRight:"75px",maxWidth:"150px"}} alt="visa"/>
                <img src={intouch} alt="intouch"/>
                <img src={mercuryo} alt="mercurio"/>
                <img src={orange} style={{marginRight:"30px"}} alt="orange"/>
                <img src={mobi} alt="mobimali"/>
                <img src={wizall} alt="wizall"/>
                <img src={mobiBurki} style={{marginRight:"120px"}} alt="mobiburki"/>
                <img src={mtn} alt="mtn"/>
                <img src={sepa} alt="sepa"/>
                <img src={moov} alt="moov"/>
            </div>
            {/* <div className="circle"></div> */}
            {/* <div className="circle"></div> */}
            {/* <div className="circle"></div> */}
            {/* <div className="circle"></div> */}
        </div>
    )
}

export default Partner
