import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.jsx';
import reportWebVitals from './reportWebVitals';


// const addEventListener=Node.prototype.addEventListener
// Node.prototype.addEventListener=function(type, listener, options) {
//   if(type==='wheel' && this.id==='root') {
//     return ;
//   }
//   return addEventListener.apply(this, arguments)
// }

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
