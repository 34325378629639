import React, { useState, useEffect } from 'react'
import {useHistory} from 'react-router-dom'
import { connect } from 'react-redux'
import { Modal } from 'react-responsive-modal'
import ReactLoading from 'react-loading'
import { useTranslation } from 'react-i18next'
import { Formik, useFormik } from 'formik';
import * as yup from 'yup';
import { Helmet } from "react-helmet";


import Widget from './Widget';
import {Input} from '../addons/input/Input'
import {randomId, getCryptoRate, sendToApi, roundDecimal} from '../../utils/utilFunctions'
import { xafChange, euroChange, cryptoChange } from './handleCard';

var WAValidator = require('trezor-address-validator');
const max = {
    btc: parseInt(process.env.REACT_APP_BUY_BTC_MAX),
    eth: parseInt(process.env.REACT_APP_BUY_ETH_MAX),
    usdt: parseInt(process.env.REACT_APP_BUY_USDT_MAX),
    trc: parseInt(process.env.REACT_APP_BUY_TRX_MAX) 
}
const min = {
    btc: parseInt(process.env.REACT_APP_BUY_BTC_MIN),
    eth: parseInt(process.env.REACT_APP_BUY_ETH_MIN),
    usdt: parseInt(process.env.REACT_APP_BUY_USDT_MIN),
    trc: parseInt(process.env.REACT_APP_BUY_TRX_MIN)
}
const enable = process.env.REACT_APP_BUY_ENABLE
var interval;

function BuyCryptoCard({Amount, User}) {
    
    const Fees = roundDecimal(process.env.REACT_APP_BUY_CARD_FEES, 3)
    const { t } = useTranslation()

    const validationSchema = yup.object({
        wallet: yup.string().required(`${t('formikBuy2')}`),    //.matches(regWallet, `${t('formikBuy2')}`),
        xaf: yup.string().required(`${t('formikBuy3')}`),
        eu: yup.string().required(`${t('formikBuy4')}`),
        amount: yup.string().required(`${t('formikBuy5')}`),
        phone: yup.string().required(`${t('formikBuy6')}`),
        cfphone: yup.string().required(`${t('formikBuy7')}`)
    })

     //initialisation of formik validation
     const formik = useFormik({
        initialValues: {
            wallet: "", xaf: 0,eu: 0,amount: 0,
            rate: { EUR: 0, USD: 0 }, fiat: 'EUR', crypto: "BTC",
            blockchain: "btc",
            rateApi: {
                "USD": 0, "XAF": 0, "XOF": 0,
                "BTC": { "USD": 0, "EUR": 0, "XAF": 0 },
                "ETH": { "USD": 0, "EUR": 0, "XAF": 0 },
                "USDT": { "USD": 0, "EUR": 0, "XAF": 0 }
            }
        },
        validationSchema,
        onSubmit: async () => {
            Eclip()
        }
    })

    const [operationId, setId]=useState({id: randomId('BC'), status: false})
    // initialisation des taux de changes
    const [rate, setRate] = useState({BCH: 575.69, BTC: 0, ETH: 2075.48})
    //initialisation de la valeur reele de usdt en francs
    const [forex, setForex] = useState({ USD: 0, XAF: 0, XOF: 0 })

    // initialisation du state du composants
    const [state, setState] = useState({
        crypto: "BTC", operator: "", amount: 0, xaf: 0, eu: 0, rate: rate.BTC, wallet: ""
    })
    // initialisation du state des erreurs
    const [errors, setErrors]= useState({
        xaf: false, wallet: false, number: false, confirmNumber: false
    })
    const [modal, setModal] = useState(false)
    const [taux, setTaux] = useState(["TRC",{XAF:0,EUR:0,USD:0}])
    const openModal=()=>setModal(!modal)
    let history=useHistory()
    
    useEffect(async() => {
        // setTimeout(() => { document.getElementById('BTC').classList.toggle('cryptoActif') }, 1)

        fetch(process.env.REACT_APP_API_URL + "currencies")   //https://api-beta-05.herokuapp.com/api/currencies
            .then(resp => resp.json())
            .then(data => {
                setForex(data)
                formik.setValues({ ...formik.values, rateApi: data }, true)
                console.log("les data",data)
            })
        .catch(err => 0)

        interval = setInterval(() => {

            fetch(process.env.REACT_APP_API_URL + "currencies")   //https://api-beta-05.herokuapp.com/api/currencies
                .then(resp => resp.json())
                .then(data => {
                    setForex(data)
                    formik.setFieldValue({ rateApi: data }, true)
                    console.log("les data du bas 2",data)
                })
                .catch(err => 0)

        }, 60 * 1000)


        return () => {
            clearInterval(interval)
        }

    }, [])

    // useEffect(async() => {
    //     getCryptoRate().then(newRate=>{
    //         console.log(newRate)
    //         if(rate) {
    //             //setState
    //             // setRate({...rate, BTC: newRate})
    //             setState({...state, rate: newRate, ...xafChange(Amount, newRate,forex)})

    //         }
    //     })
    //     setInterval(() => {
    //         let interval=getCryptoRate().then(newRate=>{
    //             // if(rate) setRate({...rate, BTC: newRate})
    //         })
    //         return () => {
    //             clearInterval(interval)
    //         }
    //     }, 60*1000);
    // }, [])

    // la fonction qui gere les changement des inputs
    const handleChange=e=>{
        let newState=state
        newState[e.name]=e.target.value
        setState({...state})
    }
    // la fonction qui gere l'evenement onBlur des inputs
    const handleBlur=e=>{
        if(e.target.value==="") {
            let newErrors=errors
            newErrors[e.name]=true
            setErrors({...errors})
        } else {
            let newErrors=errors
            newErrors[e.name]=false
            setErrors({...errors})
        }
    }
    // fonction qui gere les changements de montants
    const amountChange=e=>{
        console.log("le handle lance")
        let result
        switch (e.target.name) { // amount c'est le montant en crypto monnaie 
            case "xaf":
                result=xafChange(e.target.value, rate.BTC,forex.XAF)
                // setState({...state, ...result})
                formik.setValues({ ...formik.values, ...result }, true)
            break;
            
            case "eu":
                result=euroChange(e.target.value, rate.BTC,forex.XAF)
                // setState({...state, ...result})
                formik.setValues({ ...formik.values, ...result }, true)
            break;
            default:
            break;
        }
    }
    // fonction qui gere l'activation du bouton
    const active=()=>{
        if( (state.amount && state.wallet) )
            return false
        else return true
    }

    const start=async()=>{
        setId({...operationId, status: true})
        let params={
            transaction_id: operationId.id,
            cryptoCurency: state.crypto,
            amountCrypto: state.amount,
            amountFiat: state.xaf,
            phone: "+237",
            wallet: state.wallet,
            status: 'init',
            userId: User.userId,
            // type: 'credit_card',
        }
        let result=await sendToApi('cardinit', params, User.token)
        if(result.response) {
            setId({...operationId, status: false})
            openModal()
        } else setId({...operationId, status: false})
    }
    

// console.log("le modal est ouvert", modal)
console.log("les valeurs de la crypto", formik.values.crypto)
    return (
        <div className="buycrypto">
            <Helmet>
                <title>Achat de crypto-monnaies par cartes de credit</title>
            </Helmet>
            {enable === "FALSE" ? <h3 className='disjoint'>{t("sellCrypto18")} </h3> : ""}

            <h1>{t('buyCryptoCardTitle')}</h1>
            <div className="buy-container">
                <div className="rate">
                    <h3>{t('buyCryptoCardSous1')}</h3>
                    <div className=""> 1 {taux[0]} === {Math.round(taux[1].XAF)} XAF === {taux[1].EUR} EU </div>
                    <span>{t('buyCryptoCardSous3')}  <a href="https://www.coindesk.com/coindesk-api" target="_blank">{t('buyCryptoCardSous2')}  </a> </span> 
                </div>
                <div className="form">
                    <div className="form-group">
                        <Input val={formik.values.xaf}  label={t('buyCryptoCardSous4')} name="xaf" type="number" help={t('buyCryptoCardSous10')} change={(e) => amountChange(e)} error={state.xaf<10000&&state.xaf!==0}   />
                    </div>
                    <div className="form-group">
                        <Input val={formik.values.eu}  label={t('buyCryptoCardSous5')} name="eu" type="number" help={t('buyCryptoCardSous11')} change={(e) => amountChange(e)} />
                    </div>
                    {/* <div className="form-group">
                        <Input val={formik.values.amount} label={t('buyCryptoCardSous6')} name="crypto" type="number" help={t('buyCryptoCardSous12')} change={(e) => amountChange(e)}  />
                    </div> */}
                    <div className="form-group">
                        <Input val={formik.values.wallet} label={t('buyCryptoCardSous7')} name="wallet" help={t('buyCryptoCardSous13')} error={errors.wallet} change={handleChange} handBlur={handleBlur}  />
                    </div>
                    
                    <div className="form-group">
                        <button disabled={active() || (state.crypto===0) || state.xaf===0 } 
                            onClick={start} 
                        > 
                            {(state.rate===0) ? t('buyCryptoCardSous8') : t('buyCryptoCardSous9') }  
                        </button>
                    </div>
                {/* 13tuVVNDH1PLfUEiTEPkMDRQfTRVHyiYn2 */}
                </div>
            </div>
            <Widget open={modal} close={modal} amount={state.eu} crypto="BTC" wallet={state.wallet} opId={operationId.id} />

            <Modal open={operationId.status} showCloseIcon={false} center classNames={{modal: 'transparent-modal'}} closeOnOverlayClick={false}>
                <ReactLoading type="spin" color='#CC1616' height={150} width={150} />
            </Modal>
        </div>
    )
}

const mapStateToProps=state=>({Amount: state.cryptoAmountReducer.crypto, User: state.userReducer.user})

export default connect(mapStateToProps)(BuyCryptoCard)
