const crypto = require('crypto')

// const okay="-----BEGIN PUBLIC KEY-----MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAtn2Y3eTs3ZXLcC1m3BtUALSYisaQ4/0W8kAXO6biDrjqLvHc0Gax5xHdZQpw6BDa/OL1J2kAMJ4PNvYpT2DMcMduMgap41cCh7Lzm5Jq2Bm/aJECBJQXVoa7gLqdiqeTMY3SqDTafmpIlziRivxYR2RbIx0Fy+xgkH6mIvKf3NhViPcfBCaNy1IIIujqMzkLGCzG6doafpUTVYU9wVBlJWn16IqM2fVr4UDpkYW3zXlesZqDDRCEoX1IyN2SKtx6uXQAiQ6Og8wV19pVWIk3bx/tWziz/10URXGZlN6IlSSxIb/1nQaCPSNPelHlOBV0iq2hmeeOc4LUW8YVlryMaclbefXHJZ5CmUM1REZgu6mchWxg1g0LwlFe3uWlxeVvJNSBZ3D62NMC+foIX+fQYUdrWjkieYLP519p7NF68mdn/MT7MJRwhhUoEZ1c06jyguvSXv1r2k9+NSv2sDJdzku8wXYzPkplwIa5x7LGqJqmIzPKKYapCJeiCN+NI1k2inPgoXZnfDP1udYoc0NYNZa6oyKueEj3tTR9rNeMOGfAwbwFQTkZJNWMJqyUGmnUvqvteMAdvtAadbe/hrSqblF7buA4XEgMKNz2SZccPfmUs6Zjge+siU9fKi8F8Rr9DAeSoak71Nt4ZJmAYjOdbx6lQwWrn6QzgL0bC8AuLJECAwEAAQ==-----END PUBLIC KEY-----"

const okay = "-----BEGIN PUBLIC KEY-----MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAxz2oGQrUPedlF5MPIlwtaAP+6xE0BNW7q49vNwWPBRhmW4iFOgebzlvUhZpmAefwtmLOKhEdxXcETkr6WaPVSsp0NSQujudLb8J2nKVyKHu6TaaMxLCytPLU092KSw8XcEbegenm+TNU9l4+8A+P/S/Ioc+CY/fxTO4w4UzjEhTNZZ0zAiqpikpWhpu7H8tAG+VQPkqD5HtvI2xPfQ+NNKNFLJr4vlXezIO76BJo4zgw/hT0enX1f1PIs3/I/p14nJAD7uyyimPJHLFfpE4SmEg9YrW2ibbk0krm+I2wQM4WK2B9UMo0ygLBnr51CianhJVYBjd1qTkiavP3f9IrOqTpN1LbjecMO3C4koExE+yKsMykUh/I+NR5pcNSdb7bXhSdpYQIa3pQQlr88TlfP896NWC9EqM/hX3wWSLXf1EKUSCK7jIinJ2dV3HaPxJMyf34yATiNgPzAiDc/kXs9AAuhMq6CsQCggw4aEsrqqiLatITnDswF7wqW2tQxgffhSfoexY5Sx5ub2UfkcjUQtk5yNtDKDPRLZGsX4DYXnfu8wamH4vVPFuhgDlcWzWLbyCs9fuaqzL9OYfTOp0KMfnBtSHvxdyzpuO6bfXSBcs5g070NgyS6CrjPQSsx9cNQWU7KwbP7pe7jSUJBwLaJp5gWgnB585X5ST/DpESjUMCAwEAAQ==-----END PUBLIC KEY-----"

const crypt = (message) => {
    var encrypt = Buffer.from(message)
    return crypto.publicEncrypt(okay, encrypt).toString('base64')
}

export default crypt